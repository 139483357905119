(function ($, window) {
    const messageHandlers = {};
    const readyElements = [];

    messageHandlers.hello = function (iframe, data, responder) {
        if (!readyElements.includes(iframe)) {
            return;
        }

        setIframeWidth(iframe, '100%');
        forceIframeRendering(iframe);

        const resize = function () {
            if (iframe.contentWindow) {
                responder('resize');
                return;
            }

            $(window).unbind('resize:h5p-resizer');
        }

        $(window).on('resize:h5p-resize', resize, false);

        responder('hello')
    }

    messageHandlers.prepareResize = function (iframe, data, responder) {
        if (iframe.clientHeight === data.scrollHeight &&
            data.scrollHeight === data.clientHeight) {
            return;
        }

        setIframeHeight(iframe, data.clientHeight + 'px');

        responder('resizePrepared');
    };

    messageHandlers.resize = function (iframe, data) {
        setIframeHeight(iframe, data.scrollHeight + 'px');
    };

    messageHandlers.loaded = function (iframe, data, responder) {
        readyElements.push(iframe);
        responder('ready')
    }

    function setIframeWidth(iframe, width) {
        $(iframe).css('width', width);
    }

    function setIframeHeight(iframe, height) {
        $(iframe).css('height', height);
    }

    function forceIframeRendering(iframe) {
        // Workaround for chrome to force the re-rendering of an iframe.
        iframe.getBoundingClientRect();
    }

    function postMessage(target, action, origin) {
        console.debug('Outgoing message: ' + action)
        target.postMessage({
            'action': action,
            'context': 'h5p'
        }, origin);
    }

    function handleMessage(message) {
        if (message.hasOwnProperty('originalEvent')) {
            message = message.originalEvent;
        }

        if (message.data.context !== 'h5p') {
            return;
        }
        console.debug('Incoming message: ' + message.data.action, message.data);

        let iframe;

        getH5PElements().each(function () {
            if (this.contentWindow === message.source) {
                iframe = this;

                return false;
            }
        });

        if (!iframe) {
            return;
        }

        const handler = messageHandlers[message.data.action];
        if (!handler) {
            return;
        }

        handler(iframe, message.data, function (action) {
            postMessage(message.source, action, message.origin);
        });
    }

    function getH5PElements() {
        return $('.js-h5p, .mce-js-h5p');
    }

    $(window.document).ready(function () {
        $(window).on('message', handleMessage);
    });
})(jQuery, window);
